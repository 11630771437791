import { HiOutlineMenuAlt3 } from 'react-icons/hi'

function NavBar() {
  return (  
    <nav className='fixed top-0 w-full mx-auto flex items-center justify-between px-6 md:px-36 py-1 md:py-4 bg-white shadow-sm'>
        {/* logo */}
        <div>
            <a href='/' className='text-bold text-xl md:text-2xl'>KIM</a>
        </div>

        {/* toggle icon */}
        <div>
            <HiOutlineMenuAlt3 className='text-bold text-xl md:text-2xl md:hidden' />
        </div>

        {/* as for desktop view */}
        <div className='hidden text-xl md:flex space-x-8'>
            <a className='hover:bg-violet-600 active:bg-violet-700 ' href="/">Home</a>
            <a href="/">About</a>
            <a href="/">Skills</a>
            <a href="/">Blog</a>
            <a href="/">Contact</a>
        </div>
    </nav>
  )
}

export default NavBar