import {
  AiFillGithub,
  AiFillLinkedin,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { BsBriefcaseFill, BsArrowDownCircle } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import kimdp from "../kimdp-half.png";

import Typewriter from "typewriter-effect";

function Profile2() {
  return (
    <div className="h-screen w-screen overflow-hidden flex flex-col justify-around">
      {/* profile card */}
      
      <div className="mt-6 w-4/5 mx-auto shadow-2xl py-4 sm:p-6 sm:shadow-2xl md:w-2/5 md:shadow-none md:mx-0">
            
        <img src={kimdp} alt="kimdp" className='md:hidden object-cover rounded-full border border-green-600 shadow-xl mx-auto h-36 w-36 sm:-mt-2 sm:h-44 sm:w-44' />

            
            <h1 className="text-3xl text-center font-bold pt-2">Kevin Kimani</h1>
            <div className="pt-3 border-b-2 border-green-500 opacity-25"></div>
            
            <div className='space-y-3 mt-8'>
                <div className='flex space-x-3 justify-center'>
                    <BsBriefcaseFill className='text-2xl' />
                    <span>Full Stack Web Developer</span>
                </div>

                <div className='flex space-x-3 justify-center'>
                    <MdLocationOn className='text-2xl' />
                    <span>Nairobi, Kenya</span>
                </div>
            </div>
            <div className='mt-4 text-center'>
                <Typewriter
                    options={{
                        strings: ['React', 'Node.js', 'Django'],
                        autoStart: true,
                        loop: true,
                    }}
                    />
            </div>
            

            <div className="mt-8 pb-8 flex justify-center">
                <button className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full">
                Get In Touch
                </button> 
            </div>

            {/* social icons */}
            <div className='flex space-x-2 justify-center'>
                <AiFillGithub className='text-3xl' />
                <AiFillLinkedin className='text-3xl' />
                <AiFillTwitterCircle className='text-3xl' />
            </div>
        </div>

      {/* arrow down */}
        <div className='w-4/5 mx-auto flex items-center justify-center'>
                <BsArrowDownCircle className='text-2xl md:text-3xl text-bold animate-bounce' />
        </div>
    </div>
  );
}

export default Profile2;
