import NavBar from './components/NavBar';
// import Mainpage from './components/Mainpage';
// import Banner from './components/Banner';

import Profile2 from "./components/Profile2";


function App() {
  return (
    <div className=''>
      <NavBar />
      <Profile2 />
    </div>
  );
}

export default App;
